import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Swal from 'sweetalert2'
import Vue2Filters from 'vue2-filters'
import globalMixin from './mixins/globalMixin'
//import VueResource from 'vue-resource'
import VueForm from 'vue-form';
import options from './validations/validations'
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import ColumnVue from 'primevue/column';
import CalendarVue from 'primevue/calendar';
import PanelVue from 'primevue/panel';
import CardVue from 'primevue/card';
import DialogVue from 'primevue/dialog';
import MenuBar from 'primevue/menubar';
import RadioButton from 'primevue/radiobutton';
import CheckBox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import ToolBar from 'primevue/toolbar'
import PickList from 'primevue/picklist';
import FileUpload from 'primevue/fileupload'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import AutoComplete from 'primevue/autocomplete';
import MultiSelect from 'primevue/multiselect';
import VueBarcode from 'vue-barcode';


import VueJsPanel from 'vue-js-panel/src'
import 'jspanel4/dist/jspanel.min.css'


import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

Vue.use(VueJsPanel)
Vue.use(Vue2Filters)

import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'primeicons/primeicons.css'
//import 'primevue/datatable/datatable.umd.min.js'








Vue.use(PrimeVue, {

  locale: {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
    dayNamesShort: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    today: 'Hoy',
    clear: 'Limpiar',
    dateFormat: 'dd-mm-yy',
    weekHeader: 'Sem'
  },

});
Vue.component('DataTable', DataTable);
Vue.component('ColumnVue', ColumnVue);
Vue.component('CalendarVue', CalendarVue);
Vue.component('PanelVue', PanelVue);
Vue.component('CardVue', CardVue);
Vue.component('DialogVue', DialogVue);
Vue.component('MenuBar', MenuBar);
Vue.component('RadioButton', RadioButton);
Vue.component('CheckBox', CheckBox);
Vue.component('InputText', InputText);
Vue.component('InputNumber', InputNumber);
Vue.component('ToolBar', ToolBar);
Vue.component('PickList', PickList);
Vue.component('FileUpload', FileUpload);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('AutoComplete', AutoComplete);
Vue.component('MultiSelect', MultiSelect);
Vue.component('BarCode', VueBarcode);





window.$ = window.jQuery = require('jquery')

Vue.component('vueDropzone', vue2Dropzone)
Vue.config.productionTip = false
Vue.mixin(globalMixin)
//Vue.use(VueResource);

//Validaciones
Vue.use(VueForm);
Vue.use(VueForm, options);

window.axios = axios
//window.apiURL = 'https://logistica.assistant-dataw.com/'
window.apiURL = 'http://localhost:44374/'
window.router = router
window.Swal = Swal
window.Fire = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
