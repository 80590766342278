//import axios from "axios"

import router from "@/router"

/* eslint-disable */
const globalMixin = {
    data() {
        return {
            permiso: {
                bCreated: false,
            }
        }
    },
    methods: {
        async checkPermisions(sRoute, sObject) {
            if (localStorage.idP == undefined) {
                router.push('/')
            }

            console.log(sRoute)

            await axios.get(apiURL + 'Usuarios/checkPermission?sRoute=' + sRoute + '&idPerfil=' + localStorage.idP + '&sObject=' + sObject)
                .then((response) => {
                    if (response.data.bIsCorrect == false) {
                        Swal.fire(response.data.sErrors[0])
                        window.localStorage.clear()
                        router.push('/')
                    }
                    this.permiso = response.data.permisos
                })
                .catch((error) => {
                    Swal.fire(error)
                })
        },
        logout() {
            router.push('/')
        },
        openLoading(title) {
            Swal.fire({
                title,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        },
        roundFormat(value, decimals) {
            return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
        },
        currencyFormat(value) {
            return '$' + this.roundFormat(value, 2);
        },
        formatDateTimeToSet(dateTime) {
            const s = dateTime.toString()
            const d = new Date(s);
            return d
        },
        formatDateTimeToSave(date) {
            //const date = new Date(value.toString());
            const s = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0') + ' ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':' + date.getSeconds().toString().padStart(2, '0') + '+00:00'
            const d = new Date(s);
            return d
        },
        formatDateTime(value) {
            const date = new Date(value.toString());
            return date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
        },

        formatDate(value) {
            let date = ''
            if (typeof (value) === 'string') {
                date = new Date(value)
            }
            else {
                date = new Date(value.toString());
            }

            return date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear()
        },

        formatDateToSet(value) {
            const date = new Date(value);
            const s = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0') + ' 12:00:00+00:00'
            const d = new Date(s);
            console.log('Fecha: ' + d)
            return d
        },
        sumOfArrayWithParameter(array, parameter) {
            if (array.length == 0)
                return 0;

            let sum = null;
            if (array && array.length > 0 && typeof parameter === 'string') {
                sum = 0;
                for (let e of array) if (e && e.hasOwnProperty(parameter)) sum += e[parameter];
            }
            return sum;
        },

        /*         localeEs() {
                    return this.es
                } */

    }
}

export default globalMixin