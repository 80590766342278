<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<style>

</style>

<script>
/* eslint-disable */
export default {
  name: 'App',
  components: {

  },
  data(){
    return {

    }
  },
  methods: {

  },
  mounted() {

  },
  created() {

  }

}
</script>
