import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },

  {
    path: '/logistica/',
    name: 'MainLayout',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/Home.vue')
      },
    ]
  },

  {
    path: '/reportes/',
    name: 'Reportes',
    component: () => import('../layouts/ReportesLayout.vue'),
    children: [
      {
        path: 'reportesHome/',
        name: 'ReportesHome',
        component: () => import('../views/Homes/ReportesHome.vue'),
      },
      {
        path: 'viajesReport/',
        name: 'ViajesReport',
        component: () => import('../views/Reportes/ViajesReport.vue'),
      },
      {
        path: 'gastosReport/',
        name: 'GastosReport',
        component: () => import('../views/Reportes/GastosReport.vue'),
      },
      {
        path: 'tareasAdministrativas/',
        name: 'TareasAdministrativas',
        component: () => import('../views/Reportes/TareasAdministrativas.vue'),
      },
    ]
  },

  {
    path: '/servicios/',
    name: 'Servicios',
    component: () => import('../layouts/ServicioLayout.vue'),
    children: [
      {
        path: 'servicioHome/',
        name: 'ServicioHome',
        component: () => import('../views/Homes/ServicioHome.vue'),
      },
      {
        path: 'mantainence/',
        name: 'Mantainence',
        component: () => import('../views/Mantainence/Mantainence.vue'),
      },
      {
        path: 'correctivo/',
        name: 'Correctivo',
        component: () => import('../views/Mantainence/Correctivo.vue'),
      },
    ]
  },
  {
    path: '/admin/',
    name: 'Admin',
    component: () => import('../layouts/AdministracionLayout.vue'),
    children: [
      {
        path: 'adminHome/',
        name: 'AdminHome',
        component: () => import('../views/Homes/AdministracionHome.vue'),
      },
      {
        path: 'extTarifas',
        name: 'ExtTarifas',
        component: () => import('../views/ExtTarifas/ExtTarifas.vue')
      },
      {
        path: 'tarifas',
        name: 'Tarifas',
        component: () => import('../views/Tarifas/Tarifas.vue')
      },
      {
        path: 'tarifasGenerales',
        name: 'TarifasGenerales',
        component: () => import('../views/Tarifas/TarifasGenerales.vue')
      },
      {
        path: 'prefacturas',
        name: 'Prefacturas',
        component: () => import('../views/Facturacion/Prefacturas.vue')
      },

      {
        path: 'entrega',
        name: 'Entrega',
        component: () => import('../views/Liquidaciones/Entrega.vue')
      },
      {
        path: 'valija',
        name: 'Valija',
        component: () => import('../views/Catalogos/Valija.vue')
      },
      {
        path: 'valijaDocument/:idValija',
        name: 'ValijaDocument',
        component: () => import('../components/Valijas/ValijaDocument.vue'),
        props: true
      },
      {
        path: 'reporteDetalle',
        name: 'ReporteDetalle',
        component: () => import('../views/Programacion/Monitor/ReporteDetalle.vue')
      },
    ]
  },
  {
    path: '/catalogos/',
    name: 'Catalogos',
    component: () => import('../layouts/CatalogosLayout.vue'),
    children: [
      {
        path: 'catalogosHome',
        name: 'CatalogosHome',
        component: () => import('../views/Catalogos/CatalogosHome.vue')
      },
      {
        path: 'planeacion',
        name: 'Planeacion',
        component: () => import('../views/Catalogos/Planeacion.vue')
      },
      {
        path: 'adminNeumaticos',
        name: 'AdminNeumatico',
        component: () => import('../views/Neumaticos/AdminNeumaticos.vue')
      },
      {
        path: 'neumaticos',
        name: 'Neumaticos',
        component: () => import('../views/Catalogos/Neumaticos.vue')
      },
      {
        path: 'permisos',
        name: 'Permisos',
        component: () => import('../views/Catalogos/Permisos.vue')
      },
      {
        path: 'clientes',
        name: 'Clientes',
        component: () => import('../views/Catalogos/Clientes.vue')
      },
      {
        path: 'origenes',
        name: 'Origenes',
        component: () => import('../views/Catalogos/Origenes.vue')
      },
      {
        path: 'destinos',
        name: 'Destinos',
        component: () => import('../views/Catalogos/Destinos.vue')
      },
      {
        path: 'lineaTransporte',
        name: 'LineaTransporte',
        component: () => import('../views/Catalogos/LineaTransporte.vue')
      },
      {
        path: 'unidades',
        name: 'Unidades',
        component: () => import('../views/Catalogos/Unidades.vue')
      },
      {
        path: 'operadores',
        name: 'Operadores',
        component: () => import('../views/Catalogos/Operadores.vue')
      },
      {
        path: 'rutas',
        name: 'Rutas',
        component: () => import('../views/Catalogos/Rutas.vue')
      },
      {
        path: 'mantenimiento',
        name: 'Mantenimiento',
        component: () => import('../views/Catalogos/Mantenimiento.vue')
      },
      {
        path: 'talleres',
        name: 'Talleres',
        component: () => import('../views/Catalogos/Talleres.vue')
      },
    ]
  },
  {
    path: '/transporte/',
    name: 'MainLayout',
    component: () => import('../layouts/TransporteLayout.vue'),
    children: [
      {
        path: 'transporteHome',
        name: 'TransporteHome',
        component: () => import('../views/Transporte/TransporteHome.vue')
      },
      {
        path: 'citaEmbarque',
        name: 'CitaEmbarque',
        component: () => import('../views/Transporte/CitasEmbarque/CitaEmbarque.vue')
      },
      {
        path: 'crearCitaEmbarque',
        name: 'CrearCitaEmbarque',
        component: () => import('../views/Transporte/CitasEmbarque/CrearCitaEmbarque.vue')
      },
      {
        path: 'editarCitaEmbarque/:idEmbarque/',
        name: 'EditarCitaEmbarque',
        component: () => import('../views/Transporte/CitasEmbarque/EditarCitaEmbarque.vue'),
        props: true
      },


    ]
  },
  {
    path: '/blank/',
    name: 'BlankLayout',
    component: () => import('../layouts/BlankLayout.vue'),
    children: [
      {
        path: 'ordenServicio/:idMantPreventivo/',
        name: 'OrdenServicio',
        component: () => import('../views/Mantainence/OrdenServicio.vue'),
        props: true
      },
      {
        path: 'ordServCorrectivo/:idMantCorrectivo/',
        name: 'OrdServCorrectivo',
        component: () => import('../views/Mantainence/OrdServCorrectivo.vue'),
        props: true
      },
      {
        path: 'comprobarGastos/:idEmbarque/',
        name: 'ComprobarGastos',
        component: () => import('../views/Transporte/Embarque/FormatoGasto.vue'),
        props: true
      },
      {
        path: 'reporteEmbarque/:idEmbarque/',
        name: 'ReporteEmbarque',
        component: () => import('../views/Transporte/Embarque/ReporteEmbarque.vue'),
        props: true
      },
      {
        path: 'reporteCitaEmbarque/:idEmbarque/',
        name: 'ReporteCitaEmbarque',
        component: () => import('../views/Transporte/CitasEmbarque/ReporteCitaEmbarque.vue'),
        props: true
      },
      {
        path: 'reporteComprobacion/:idComprobacionAdmin/',
        name: 'reporteComprobacion',
        component: () => import('../components/Gastos/ComprobacionDocumento.vue'),
        props: true
      },
    ]
  },
  {
    path: '/embarque/',
    name: 'MainLayout',
    component: () => import('../layouts/TransporteLayout.vue'),
    children: [
      {
        path: 'embarque',
        name: 'Embarque',
        component: () => import('../views/Transporte/Embarque/Embarque.vue')
      },
      {
        path: 'historicoEmbarque',
        name: 'HistoricoEmbarque',
        component: () => import('../views/Transporte/Embarque/HisoricoEmbarque.vue')
      },
      {
        path: 'consultaEmbarque/:idEmbarque/',
        name: 'ConsultaEmbarque',
        component: () => import('../views/Transporte/Embarque/ConsultaEmbarque.vue'),
        props: true
      },
      {
        path: 'crearEmbarque/:idEmbarque/',
        name: 'CrearEmbarque',
        component: () => import('../views/Transporte/Embarque/CrearEmbarque.vue'),
        props: true
      },
      {
        path: 'monitorEmbarques',
        name: 'MonitorEmbarques',
        component: () => import('../views/Transporte/Embarque/MonitorEmbarques.vue')
      },

      {
        path: 'gastos/:idEmbarque/',
        name: 'Gastos',
        component: () => import('../views/Transporte/Embarque/Gastos.vue'),
        props: true
      },
      {
        path: 'comprobacionGastos/:idEmbarque/',
        name: 'ComprobacionGastos',
        component: () => import('../views/Transporte/Embarque/ComprobacionGastos.vue'),
        props: true
      },
      {
        path: 'tabGastos/:idEmbarque/',
        name: 'TabGastos',
        component: () => import('../views/Transporte/Embarque/TabGastos.vue'),
        props: true
      },

      {
        path: 'registroEntregas/:idEmbarque/',
        name: 'RegistroEntregas',
        component: () => import('../views/Transporte/Embarque/RegistroEntregas.vue'),
        props: true
      },

      {
        path: 'gastosAdmin',
        name: 'GastosAdmin',
        component: () => import('../views/Transporte/Embarque/GastosAdmin.vue')
      },
      {
        path: 'comprobacionAdmin',
        name: 'ComprobacionAdmin',
        component: () => import('../views/Transporte/Embarque/ComprobacionAdmin.vue')
      },
    ]
  },
  {
    path: '/programacion/',
    name: 'MainLayout',
    component: () => import('../layouts/TransporteLayout.vue'),
    children: [

      {
        path: 'requerimientos',
        name: 'Requerimientos',
        component: () => import('../views/Programacion/Requerimientos/Requerimientos.vue')
      },
      {
        path: 'monitorProgramacion',
        name: 'MonitorProgramacion',
        component: () => import('../views/Programacion/Monitor/MonitorProgramacion.vue')
      },



    ]
  },

  {
    path: '/test/',
    name: 'Test',
    component: () => import('../views/Test.vue')
  }

]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
